import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  Grid,
  Modal,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import clsx from 'clsx';
import { useState } from 'react';
import { useEffect } from 'react';
import ToastManager from '../toaster/ToastManager';
import { addProductMappings } from '../../utils/api';

const AddProductColorModal = ({ open, onClose, product, className, ...rest }) => {
  const classes = useStyles();

  const [colorName, setColorName] = useState('');
  const [colorCode, setColorCode] = useState('#');
  const [variantId, setVariantId] = useState('');
  const [loading, setLoading] = useState(false);
  const [sizeMappings, setSizeMappings] = useState([]);

  useEffect(() => {
    if (product) {
      setSizeMappings(
        product.sizes.map(sizeItem => ({
          size: sizeItem.size,
          active: true,
          variantId: '',
        })),
      );
    }
  }, [product]);

  const handleVariantIdChange = (e, index) => {
    sizeMappings[index].variantId = e.target.value;
    setSizeMappings([...sizeMappings]);
  };

  const handleActiveChange = (e, index) => {
    sizeMappings[index].active = e.target.checked;
    setSizeMappings([...sizeMappings]);
  };

  const onSave = async () => {
    try {
      if (!CSS.supports('color', colorCode)) {
        return ToastManager.error('Color code is invalid.');
      }

      for (const mappingItem of sizeMappings) {
        if (mappingItem.active && !mappingItem.variantId) {
          return ToastManager.error('Printful variant ID is required.');
        }
      }

      setLoading(true);

      let newMappings;
      if (sizeMappings.length > 0) {
        for (const variantItem of sizeMappings) {
          if (variantItem.active && !variantItem.variantId) {
            return ToastManager.error('Printful variant ID is required.');
          }
        }
        sizeMappings.map(item => ({
          ...item,
          color_label: colorName,
          color_code: colorCode,
        }));
      } else {
        if (!variantId) {
          return ToastManager.error('Printful variant ID is required.');
        }
        newMappings = [
          {
            variantId,
            productId: product.id,
            color_label: colorName,
            color_code: colorCode,
          },
        ];
      }

      await addProductMappings({ productId: product.id, mappings: newMappings });
      ToastManager.success('New color is added.');
      setColorName('');
      setColorCode('#');
      setLoading(false);
      onClose();
    } catch (error) {
      console.error(error);
      setLoading(false);
      return ToastManager.error('Something went to wrong. Try again please.');
    }
  };

  return (
    <Modal onClose={onClose} open={open}>
      <Card {...rest} className={clsx(classes.root, className)}>
        <form>
          <CardHeader title="Add New Color" />
          <Divider />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item md={2}>
                <div
                  className="colorItem"
                  style={{
                    width: 50,
                    height: 50,
                    backgroundColor: colorCode,
                  }}
                />
              </Grid>
              <Grid item md={5}>
                <TextField
                  fullWidth
                  label="Color Name"
                  onChange={e => setColorName(e.target.value.toLowerCase())}
                  value={colorName}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={5}>
                <TextField
                  fullWidth
                  label="Hex Code"
                  onChange={e => setColorCode(e.target.value)}
                  value={colorCode}
                  variant="outlined"
                />
              </Grid>
            </Grid>
            {
              sizeMappings.length === 0 ? (
                <Box sx={{ mt: 2 }}>
                  <TextField
                    fullWidth
                    label="Variant Id"
                    onChange={e => setVariantId(e.target.value)}
                    value={variantId}
                    variant="outlined"
                  />
                </Box>
              ) : (
                <Box className="my-2">
                  <Typography>
                    <b>Size Mappings</b>
                  </Typography>
                  <Table className="mt-2">
                    <TableBody>
                      <TableRow>
                        <TableCell>Size</TableCell>
                        <TableCell>Active</TableCell>
                        <TableCell>Printful Variant ID</TableCell>
                      </TableRow>
                      {sizeMappings.map((mappingItem, index) => (
                        <TableRow selected={index % 2 === 0} key={index}>
                          <TableCell className="p-1">
                            <Typography>{mappingItem.size}</Typography>
                          </TableCell>
                          <TableCell className="p-1">
                            <Switch
                              color="primary"
                              checked={mappingItem.active}
                              onChange={e => handleActiveChange(e, index)}
                            />
                          </TableCell>
                          <TableCell className="p-1">
                            <TextField
                              fullWidth
                              onChange={e => handleVariantIdChange(e, index)}
                              value={mappingItem.variantId}
                              variant="outlined"
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Box>
              )
            }
          </CardContent>
          <Divider />
          <CardActions className={classes.actions}>
            <Button onClick={onClose}>Close</Button>
            <Button
              color="primary"
              onClick={onSave}
              variant="contained"
              disabled={loading}
              startIcon={loading ? <CircularProgress size={16} /> : null}
            >
              Save
            </Button>
          </CardActions>
        </form>
      </Card>
    </Modal>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: 500,
    maxHeight: '100%',
    overflowY: 'auto',
    maxWidth: '100%',
  },
  actions: {
    justifyContent: 'flex-end',
  },
}));

export default AddProductColorModal;
