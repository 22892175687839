import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  Modal,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';
import CloseOutlined from '@material-ui/icons/CloseOutlined';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { updateProductMappings } from '../../utils/api';
import { getColor } from '../../utils/getColor';
import ToastManager from '../toaster/ToastManager';

const EditProductSizeModal = ({ open, onClose, size, className, ...rest }) => {
  const classes = useStyles();

  const [sizeName, setSizeName] = useState('');
  const [variantId, setVariantId] = useState('');
  const [colorVariants, setColorVariants] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (size) {
      setSizeName(size.size);
      setColorVariants(size.variants);
    }
  }, [size]);

  const handleActiveChange = (e, index) => {
    colorVariants[index].active = e.target.checked;
    setColorVariants([...colorVariants]);
  };

  const handleVariantIdChange = (e, index) => {
    colorVariants[index].variantId = e.target.value;
    setColorVariants([...colorVariants]);
  };

  const onUpdate = async () => {
    try {
      for (const variantItem of colorVariants) {
        if (variantItem.active && !variantItem.variantId) {
          return ToastManager.error('Printful variant ID is required.');
        }
      }
      setLoading(true);
      const updatedMappings = colorVariants.map(item => ({
        ...item,
        size: sizeName,
      }));
      await updateProductMappings({ mappings: updatedMappings });
      ToastManager.success('Successfully updated.');
      setLoading(false);
      onClose();
    } catch (error) {
      console.error(error);
      setLoading(false);
      return ToastManager.error('Something went to wrong. Try again please.');
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Card {...rest} className={clsx(classes.root, className)}>
        <form>
          <CardHeader title="Edit Size" />
          <Divider />
          <CardContent>
            <Box className="p-2">
              <TextField
                fullWidth
                label="Size"
                onChange={e => setSizeName(e.target.value.toLowerCase())}
                value={sizeName}
                variant="outlined"
              />
            </Box>
            {colorVariants.length > 0 ? (
              <Box className="p-2">
                <Table className="mt-2">
                  <TableBody>
                    <TableRow>
                      <TableCell>Color</TableCell>
                      <TableCell>Active</TableCell>
                      <TableCell>Printful Variant ID</TableCell>
                    </TableRow>
                    {colorVariants.map((mappingItem, index) => (
                      <TableRow selected={index % 2 === 0} key={index}>
                        <TableCell className="p-1">
                          <Typography>{mappingItem.color_label}</Typography>
                          <Box
                            style={{
                              backgroundColor: mappingItem.color_code,
                              borderRadius: 4,
                              border: 'solid 1px #00000012',
                            }}
                            className="flexCenterCenter p-1"
                          >
                            {mappingItem.color_code ? (
                              <Typography style={{ color: getColor(mappingItem.color_code) }}>
                                {mappingItem.color_code}
                              </Typography>
                            ) : (
                              <CloseOutlined color="secondary" />
                            )}
                          </Box>
                        </TableCell>
                        <TableCell className="p-1">
                          <Switch
                            color="primary"
                            checked={mappingItem.active}
                            onChange={e => handleActiveChange(e, index)}
                          />
                        </TableCell>
                        <TableCell className="p-1">
                          <TextField
                            fullWidth
                            onChange={e => handleVariantIdChange(e, index)}
                            value={mappingItem.variantId}
                            variant="outlined"
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            ) : (
              <Box className="p-2">
                <TextField
                  fullWidth
                  label="Printful Variant ID"
                  type="number"
                  onChange={e => setVariantId(e.target.value.toLowerCase())}
                  value={variantId}
                  variant="outlined"
                />
              </Box>
            )}
          </CardContent>
          <Divider />
          <CardActions className={classes.actions}>
            <Button onClick={onClose}>Close</Button>
            <Button
              color="primary"
              onClick={onUpdate}
              variant="contained"
              disabled={loading}
              startIcon={loading ? <CircularProgress size={16} /> : null}
            >
              Update
            </Button>
          </CardActions>
        </form>
      </Card>
    </Modal>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: 500,
    maxHeight: '100%',
    overflowY: 'auto',
    maxWidth: '100%',
  },
  actions: {
    justifyContent: 'flex-end',
  },
}));

export default EditProductSizeModal;
