import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';
import React from 'react';
import { useState } from 'react';
import { deleteProductMappings, updateProductMappingStatusApi } from '../../utils/api';
import DeleteConfirmModal from '../modals/DeleteConfirmModal';
import ToastManager from '../toaster/ToastManager';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';

const ProductMappings = ({ product, onUpdate }) => {
  const [selectedVariant, setSelectedVariant] = useState(null);
  const [loading, setLoading] = useState(false);

  const [searchVariantId, setSearchVariantId] = useState('')

  const handleDelete = variant => {
    setSelectedVariant(variant);
  };

  const deleteVariantItem = async () => {
    try {
      setLoading(true);
      await deleteProductMappings({ variantIds: [selectedVariant.id] });
      ToastManager.info('1 item is deleted');
      onUpdate();
    } catch (error) {
      console.error(error);
      ToastManager.error('Not able to delete, Try again.');
    }
    setSelectedVariant(null);
    setLoading(false);
  };

  const productVariants = (product?.variants || []).filter((item) => {
    if (!searchVariantId) return true;
    return item.variantId.toString().includes(searchVariantId);
  })

  const handleStatusChange = async (e, variantItem) => {
    const status = e.target.checked;
    await updateProductMappingStatusApi({
      variantId: variantItem.id,
      status
    })
    await onUpdate()
    ToastManager.success('1 item affected.');
  }

  return (
    <>
      <Card>
        <CardHeader title={`Product Mappings (${product?.variants.length || 0})`} />
        <Divider />
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', p: 2 }}>
          <Typography>Search Variant Id</Typography>
          <TextField
            onChange={e => setSearchVariantId(e.target.value)}
            value={searchVariantId}
            variant="outlined"
          />
        </Box>
        <CardContent>
          {product ? (
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>Size</TableCell>
                  <TableCell>Color</TableCell>
                  <TableCell>Active</TableCell>
                  <TableCell>Printful Variant ID</TableCell>
                </TableRow>
                {productVariants.map((variantItem, index) => (
                  <TableRow selected={index % 2 === 0} key={index}>
                    <TableCell>
                      <Typography>{variantItem.size}</Typography>
                    </TableCell>
                    <TableCell>
                      <Box className="flexCenter">
                        <div
                          style={{
                            backgroundColor: variantItem.color_code,
                            width: 24,
                            height: 24,
                            borderRadius: 2,
                            border: 'solid 1px #00000012',
                            marginRight: 10,
                          }}
                        >
                          {!variantItem.color_code && <CloseOutlinedIcon color="secondary" />}
                        </div>
                        <Typography>{variantItem.color_code}</Typography>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Switch color="primary" checked={variantItem.active} onChange={(e) => { handleStatusChange(e, variantItem) }} />
                    </TableCell>
                    <TableCell>
                      <Typography>{variantItem.variantId}</Typography>
                    </TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        color="secondary"
                        size="small"
                        onClick={() => handleDelete(variantItem)}
                      >
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <Box className="flexCenterCenter p-2">
              <CircularProgress />
            </Box>
          )}
        </CardContent>
      </Card>
      <DeleteConfirmModal
        open={!!selectedVariant}
        onClose={() => setSelectedVariant(null)}
        onDelete={deleteVariantItem}
        loading={loading}
      />
    </>
  );
};

export default ProductMappings;
