import React from 'react';
import { Link } from 'react-router-dom';
import classes from '../../globalcss/dash.module.css';

const OrderItem = ({ order }) => {
  const { billingAddress, orderData: printfulOrderMetadata, products } = order;

  const customer = billingAddress;

  const calculateProfit = order => {
    let paid = order.totalAmount;
    let tax = order.tax * order.price;
    let printful = Number(order.printfulOrderMetadata.costs.total);
    let stripeFee = (paid * 0.029 + 0.3).toFixed(2);
    let amount = paid - stripeFee - printful - tax;
    return amount.toFixed(2);
  };

  return (
    <Link to={`/order/${order._id}`} style={{ textDecoration: 'none', color: 'black' }}>
      <div className={classes.storeOrderCard}>
        <div>
          {/* <p style={{marginBottom: '0', fontSize: '12px'}}>#{order._id.substring(order._id.length - 6)}</p> */}
          <p style={{ marginBottom: '10px', fontSize: '16px' }}>#{order.orderNo}</p>
        </div>
        <div>
          <h4 style={{ margin: '0' }}>
            {customer.firstName} {customer.lastName}
          </h4>
        </div>
        <div>
          <p style={{ marginBottom: '10px' }}>{customer.email}</p>
        </div>
        {order.status === 'cancelled_by_printful' ? (
          <h3 style={{ color: 'red' }}>cancelled</h3>
        ) : (
          <h3>processed</h3>
        )}
        <div className={classes.stats}>
          <div
            style={{
              marginRight: '1rem',
              paddingRight: '1rem',
              borderRight: '.5px solid lightgray',
            }}
          >
            <p>mp profit</p>
            <h3 style={{ marginBottom: '0', marginTop: '.5rem', color: 'green' }}>
              ${(calculateProfit(order) * 0.3).toFixed(2)}
            </h3>
          </div>
          <div
            style={{
              marginRight: '1rem',
              paddingRight: '1rem',
              borderRight: '.5px solid lightgray',
            }}
          >
            <p>total profit</p>
            <h3 style={{ marginBottom: '0', marginTop: '.5rem' }}>${calculateProfit(order)}</h3>
          </div>
          <div>
            <p>vendor profit</p>
            <h3 style={{ marginBottom: '0', marginTop: '.5rem' }}>
              ${(calculateProfit(order) * 0.7).toFixed(2)}
            </h3>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default OrderItem;
