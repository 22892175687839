export const calculateProfit = order => {
  let paid = order.totalAmount;
  let shippingCost = order.shippingCost;
  let tax = order.tax * order.price;

  let printful = 0;
  let percent = 0.029;

  if (order.printfulOrderMetadata?.costs) {
    if (
      order.billingAddress.country != 'US' &&
      order.printfulOrderMetadata.costs.currency != 'USD'
    ) {
      percent = 0.049; // international stripe fee with currency conversion
    } else if (
      order.billingAddress.country != 'US' &&
      order.printfulOrderMetadata.costs.currency == 'USD'
    ) {
      percent = 0.039; // international stripe fee without currency conversion
    }

    printful = Number(order.printfulOrderMetadata.costs.total);
  }

  let stripeFee = (paid * percent + 0.3).toFixed(2);
  let amount = paid - stripeFee - printful - tax;
  return amount.toFixed(2);
};
