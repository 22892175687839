

const ListInput = ({ name, value = [], onChange, label }) => {

    if (!Array.isArray(value)) {
        throw new Error("value must be an array.")
    }

    if (value.length && value.some(item => typeof item !== 'string')) {
        throw new Error("item in array can be only a string.")
    }

    const handleChange = (e, index) => {
        const newValue = value;
        newValue[index] = e.target.value
        if (typeof onChange === 'function') {
            onChange({ name, value: [...newValue] })
        }
    }

    const handleAdd = () => {
        if (typeof onChange === 'function') {
            onChange({ name, value: [...value, ''] })
        }
    }

    const handleCancel = (index) => {
        if (typeof onChange === 'function') {
            value.splice(index, 1)
            onChange({ name, value: [...value] })
        }
    }

    return (
        <div style={styles.root}>
            {
                label && <div style={styles.la}>{label}</div>
            }
            {
                value.map((item, index) => (
                    <div style={styles.listItem}>
                        <input value={item} onChange={(e) => handleChange(e, index)} style={styles.listInput} />
                        <button onClick={() => handleCancel(index)} style={styles.closeButton}>X</button>
                    </div>
                ))
            }
            <div style={styles.addButtonWrapper}>
                <button onClick={handleAdd} style={styles.addButton}>+Add</button>
            </div>
        </div>
    )
}

export default ListInput;

const styles = {
    root: {
        width: '100%',
        fontFamily: "Roboto"
    },
    listItem: {
        width: '100%',
        display: 'flex',
        marginTop: 5
    },
    listInput: {

    },
    closeButton: {
        color: 'red',
        cursor: 'pointer'
    },
    addButtonWrapper: {
        width: '100%',
        display: 'flex'
    },
    addButton: {
        marginLeft: 'auto',
        fontWeight: 'normal',
        backgroundColor: 'hsl(199deg 48% 57%)',
        padding: '5px 10px',
        borderRadius: 4,
        marginTop: 5,
    }
}