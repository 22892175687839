import React, { Suspense } from 'react';
import { makeStyles } from '@material-ui/styles';
import Loading from '../components/Loading';
import NavBar from './NavBar';
import TopBar from './TopBar';
import { useAuth } from '../components/auth/AuthProvider';
import { Navigate } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  container: {
    minHeight: '100vh',
    display: 'flex',
    '@media all and (-ms-high-contrast:none)': {
      height: 0, // IE11 fix
    },
  },
  content: {
    paddingTop: 64,
    flexGrow: 1,
    maxWidth: '100%',
    overflowX: 'hidden',
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 256,
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: 56,
    },
  },
}));

const AdminLayout = ({ children }) => {
  const { isLoggedIn } = useAuth();
  const classes = useStyles();

  if (isLoggedIn === false) return <Navigate to="/login" />;

  if (isLoggedIn === undefined) return <Loading loading={true} text="Logging in..." />;

  return (
    <>
      <TopBar />
      <NavBar />
      <div className={classes.container}>
        <div className={classes.content}>
          <Suspense fallback={<Loading />}>{children}</Suspense>
        </div>
      </div>
    </>
  );
};

export default AdminLayout;
