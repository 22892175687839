import React, { useState } from 'react';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  Typography,
} from '@material-ui/core';
import { Api } from '../../utils/api';

const ControllPanel = () => {
  const [isMergeProcess, setIsMergeProcess] = useState();

  const handleMergeData = () => {
    setIsMergeProcess(true);
    Api.post('/v2/admin/update-database')
      .then(res => {
        console.log(res.data);
      })
      .finally(() => {
        setIsMergeProcess(false);
      });
  };

  return (
    <Card>
      <CardHeader title="Control Panel" />
      <Divider />
      <CardContent>
        <Typography gutterBottom variant="h6">
          Update Database
        </Typography>
        <Typography gutterBottom variant="body2">
          - This will move users and vendors data to stores.
        </Typography>
        <Button
          disabled={isMergeProcess}
          variant="contained"
          onClick={handleMergeData}
          startIcon={isMergeProcess && <CircularProgress size={20} />}
        >
          Update Database
        </Button>
      </CardContent>
    </Card>
  );
};

export default ControllPanel;
