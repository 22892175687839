import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Avatar,
  Link,
} from '@material-ui/core';
import GenericMoreButton from '../../components/GenericMoreButton';
import TableEditBar from '../../components/TableEditBar';
import getInitials from '../../utils/getInitials';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 10,
  },
  content: {
    padding: 0,
  },
  inner: {
    minWidth: 700,
  },
  nameCell: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    height: 42,
    width: 42,
    marginRight: theme.spacing(1),
  },
  actions: {
    padding: theme.spacing(1),
    justifyContent: 'flex-end',
  },
}));

const PaymentsTable = ({ className, payments, ...rest }) => {
  const classes = useStyles();
  const [selectedPayments, setSelectedPayments] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleSelectAll = event => {
    const selectedPayments = event.target.checked ? payments.map(customer => customer.id) : [];

    setSelectedPayments(selectedPayments);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedPayments.indexOf(id);
    let newSelectedPayments = [];

    if (selectedIndex === -1) {
      newSelectedPayments = newSelectedPayments.concat(selectedPayments, id);
    } else if (selectedIndex === 0) {
      newSelectedPayments = newSelectedPayments.concat(selectedPayments.slice(1));
    } else if (selectedIndex === selectedPayments.length - 1) {
      newSelectedPayments = newSelectedPayments.concat(selectedPayments.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedPayments = newSelectedPayments.concat(
        selectedPayments.slice(0, selectedIndex),
        selectedPayments.slice(selectedIndex + 1),
      );
    }

    setSelectedPayments(newSelectedPayments);
  };

  const handleChangePage = (event, page) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(event.target.value);
  };

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Typography color="textSecondary" gutterBottom variant="body2">
        {payments.length} Records found. Page {page + 1} of{' '}
        {Math.ceil(payments.length / rowsPerPage)}
      </Typography>
      <Card>
        <CardHeader action={<GenericMoreButton />} title="All payments" />
        <Divider />
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>User</TableCell>
                    <TableCell>Payout Amount</TableCell>
                    <TableCell>Stripe Account</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Date</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {payments.slice(page * rowsPerPage, (page + 1) * rowsPerPage).map(payment => (
                    <TableRow
                      hover
                      key={payment._id}
                    >
                      <TableCell>
                        {
                          payment.user && (
                            <div className={classes.nameCell}>
                              <Avatar className={classes.avatar} src={payment.user?.store?.logo}>
                                {getInitials(payment.user?.store.name)}
                              </Avatar>
                              <div>
                                <Link
                                  color="inherit"
                                  component={RouterLink}
                                  to="/"
                                  variant="h6"
                                >
                                  {payment.user?.store.name}
                                </Link>
                              </div>
                            </div>
                          )
                        }
                      </TableCell>
                      <TableCell>
                        ${payment.totalPayout}
                      </TableCell>
                      <TableCell>
                        {payment.stripeAccountId}
                      </TableCell>
                      <TableCell>
                        {payment.status}
                      </TableCell>
                      <TableCell>
                        {payment.createdAt}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>
        <CardActions className={classes.actions}>
          <TablePagination
            component="div"
            count={payments.length}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </CardActions>
      </Card>
      <TableEditBar selected={selectedPayments} />
    </div>
  );
};

PaymentsTable.propTypes = {
  className: PropTypes.string,
  payments: PropTypes.array,
};

PaymentsTable.defaultProps = {
  payments: [],
};

export default PaymentsTable;
