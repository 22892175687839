import DashboardIcon from '@material-ui/icons/DashboardOutlined';
import PeopleIcon from '@material-ui/icons/PeopleOutlined';
import ViewStreamIcon from '@material-ui/icons/ViewStream';
import PaymentIcon from '@material-ui/icons/Payment';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';
import BallotIcon from '@material-ui/icons/Ballot';
import BookIcon from '@material-ui/icons/Book';

export default [
  {
    subheader: 'Merchpals Admin',
    items: [
      {
        title: 'Dashboard',
        href: '/dashboard',
        icon: DashboardIcon,
      },
      {
        title: 'Stores',
        href: '/stores',
        icon: PeopleIcon,
      },
      {
        title: 'Orders',
        href: '/orders',
        icon: BookIcon,
      },
      {
        title: 'Sample Orders',
        href: '/sample-orders',
        icon: BookIcon,
      },
      {
        title: 'Payouts',
        href: '/payouts',
        icon: PaymentIcon,
      },
      {
        title: 'All Products',
        href: '/store-products',
        icon: BallotIcon,
      },
      {
        title: 'Products',
        href: '/products',
        icon: ViewStreamIcon,
      },
      {
        title: 'Settings',
        href: '/setting',
        icon: SettingsIcon,
      },
    ],
  },
];
