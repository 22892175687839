
import axios from 'axios'

export const postDataToURL = async (url, data) => {
    return new Promise(resolve => {
        axios
            .put(url, data, {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                },
            })
            .then(response => {
                if (response.status === 200) {
                    console.info('images uploaded successfully');
                }
            })
            .catch(error => {
                console.error(error);
            })
            .finally(() => {
                resolve();
            });
    });
};
