import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Card, Typography, Grid } from '@material-ui/core';
import useSWR from 'swr';
import { fetcher } from '../../../utils/api';
import Loading from '../../../components/Loading';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0,
  },
  item: {
    padding: 12,
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      '&:not(:last-of-type)': {
        borderRight: `1px solid ${theme.palette.divider}`,
      },
    },
    [theme.breakpoints.down('sm')]: {
      '&:not(:last-of-type)': {
        borderBottom: `1px solid ${theme.palette.divider}`,
      },
    },
  },
  valueContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  label: {
    marginLeft: 4,
  },
}));

function Overview({ className, ...rest }) {
  const classes = useStyles();

  const { data } = useSWR('v2/admin/stats', fetcher);

  const result = data?.result;

  console.log(result)

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <Loading loading={!result} />
      <Grid alignItems="center" container justifyContent="space-between">
        <Grid className={classes.item} item md={3} sm={6} xs={12}>
          <Typography component="h2" gutterBottom variant="overline">
            Merchpals Profit
          </Typography>
          <div className={classes.valueContainer}>
            <Typography variant="h3">$ {result?.mpProfit}</Typography>
          </div>
        </Grid>
        <Grid className={classes.item} item md={3} sm={6} xs={12}>
          <Typography component="h2" gutterBottom variant="overline">
            Vendor Profit
          </Typography>
          <div className={classes.valueContainer}>
            <Typography variant="h3">$ {result?.vendorProfit}</Typography>
          </div>
        </Grid>
        <Grid className={classes.item} item md={3} sm={6} xs={12}>
          <Typography component="h2" gutterBottom variant="overline">
            Tax
          </Typography>
          <div className={classes.valueContainer}>
            <Typography variant="h3"> ${result?.tax} </Typography>
          </div>
        </Grid>
        <Grid className={classes.item} item md={3} sm={6} xs={12}>
          <Typography component="h2" gutterBottom variant="overline">
            Revenue
          </Typography>
          <div className={classes.valueContainer}>
            <Typography variant="h3">${result?.revenue}</Typography>
          </div>
        </Grid>
      </Grid>
    </Card>
  );
}

Overview.propTypes = {
  className: PropTypes.string,
};

export default Overview;
