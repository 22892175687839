import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Box } from '@material-ui/core';
import AdminLayout from '../../layouts/AdminLayout';
import useSWR from 'swr';
import { fetcher } from '../../utils/api';
import SearchBar from '../../components/SearchBar';
import Header from './Header';
import StoresTable from './StoresTable';
import Loading from '../../components/Loading';

const useStyles = makeStyles(() => ({
  root: {
    padding: 24,
  },
  results: {
    marginTop: 12,
  },
}));

const Stores = () => {
  const classes = useStyles();
  const [stores, setStores] = React.useState([]);
  const [filteredStores, setFilteredStores] = React.useState([]);

  const { data, mutate: updateStores } = useSWR('v2/stores', fetcher);

  useEffect(() => {
    console.log(data);
    if (data?.result) {
      setStores(data.result);
      setFilteredStores(data.result);
    }
  }, [data]);

  function handleSearch(val) {
    setFilteredStores(stores.filter(str => str.name.toLowerCase().includes(val.toLowerCase())));
  }

  return (
    <AdminLayout>
      <Box className={classes.root}>
        <Loading loading={stores.length === 0} />
        <Header />
        <SearchBar onSearch={handleSearch} />
        <StoresTable stores={filteredStores} updateStores={updateStores} />
      </Box>
    </AdminLayout>
  );
};

export default Stores;
