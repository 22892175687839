/**
 * Main Toaster Component
 */
import { Box, Button } from '@material-ui/core';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import './dialog-style.scss';

const DialogComponent = props => {
  const { title, description, onConfirm, onCancel, icon, type } = props;

  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(true);
  }, []);

  const handleCancel = () => {
    setOpen(false);
    setTimeout(() => {
      onCancel();
    }, 1000);
  };

  const handleConfirm = () => {
    setOpen(false);
    setTimeout(() => {
      onConfirm();
    }, 1000);
  };

  let confirmTitle = 'Delete';

  if (type === 'confirm') {
    confirmTitle = 'Confirm';
  }

  return (
    <dialog open={open}>
      <div className={`dialog-root ${type}`}>
        <div className="dialog-body">
          {icon && <div className="icon">{icon}</div>}
          {title && <h2>{title}</h2>}
          {description && <p>{description}</p>}
          <Box display="flex" sx={{ mt: 2 }}>
            <Button onClick={handleCancel}>Cancel</Button>
            <Button onClick={handleConfirm} variant="contained" color="secondary" sx={{ ml: 2 }}>
              {confirmTitle}
            </Button>
          </Box>
        </div>
      </div>
    </dialog>
  );
};

export default React.memo(DialogComponent);
