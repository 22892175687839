const MpEvent = {
  on(eventName, callback) {
    if (!eventName || typeof eventName !== 'string') {
      console.error('MpEvent: eventName must be a string');
      return;
    }

    if (typeof callback !== 'function') {
      console.error('MpEvent: callback is not a function');
      return;
    }

    document.addEventListener(eventName, e => {
      const data = e.detail;
      callback(data);
    });
  },

  dispatch(eventName, data) {
    if (!eventName || typeof eventName !== 'string') {
      console.error('MpEvent: eventName must be a string');
      return;
    }
    document.dispatchEvent(new CustomEvent(eventName, { detail: data }));
  },

  remove(eventName, callback) {
    if (typeof callback === 'function') {
      document.removeEventListener(eventName, callback);
    } else if (typeof callback !== 'undefined') {
      console.error('HpEvent: callback is not a function');
    }
  },
};

export default MpEvent;
