import React from 'react';
import AdminLayout from '../../layouts/AdminLayout';
import DashboardAnalytics from './DashboardAnalytics';

const AdminDashBoard = () => {
  return (
    <AdminLayout>
      <DashboardAnalytics />
    </AdminLayout>
  );
};

export default AdminDashBoard;
