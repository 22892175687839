import React from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import EditOutlined from '@material-ui/icons/EditOutlined';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Link } from 'react-router-dom';

const ProductInfo = ({ product }) => {
  const classes = useStyles();

  return (
    <Card>
      <CardHeader title="Product Info" />
      <Divider />
      <CardContent className={classes.content}>
        {product ? (
          <Box>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>{product.name}</TableCell>
                </TableRow>

                <TableRow selected>
                  <TableCell>Min Price</TableCell>
                  <TableCell>${product.minPrice}</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>Base Price</TableCell>
                  <TableCell>${product.basePrice}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Box className="p-2 flexCol">
              <Button
                variant="contained"
                className="mt-1"
                color="primary"
                startIcon={<EditOutlined />}
              >
                EDIT
              </Button>

              <Button
                variant="contained"
                className="mt-1"
                color="secondary"
                startIcon={<DeleteOutlinedIcon />}
              >
                DELETE PRODUCT
              </Button>
              <Link to="/products">
                <Button className="mt-1" startIcon={<ArrowBackIcon />}>
                  Back To Products
                </Button>
              </Link>
            </Box>
          </Box>
        ) : (
          <Box className="flexCenterCenter p-2">
            <CircularProgress />
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0,
  },
  actions: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    '& > * + *': {
      marginLeft: 0,
    },
  },
  buttonIcon: {
    marginRight: theme.spacing(1),
  },
}));

export default ProductInfo;
