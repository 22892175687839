import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import camImage from '../assets/images/camImg.png';
import { LazyLoadImage } from 'react-lazy-load-image-component';

export const Mode = {
    FRONT: 'front',
    BACK: 'back',
};

const ProductImageWithDesign = ({
    product,
    switchButton = false,
    disableMode = false,
    color
}) => {
    const fDesign = product.designImage;
    const bDesign = product.backDesignImage;
    const [mode, setMode] = useState(!fDesign && bDesign ? Mode.BACK : Mode.FRONT);
    const slug = product.slug.replace(' ', '').toLowerCase();

    // Preload Back Image
    useEffect(() => {
        if (product.backImage) {
            new Image().src = product.backImage;
        }
        if (bDesign) {
            new Image().src = bDesign;
        }
    }, [product]);

    const handleMouseEnter = () => {
        if (!switchButton && !disableMode) {
            if (product.backImage) {
                // if (mode === Mode.FRONT) {
                //     setMode(Mode.BACK);
                // } else {
                // setMode(Mode.FRONT);
                // }
                setMode(Mode.FRONT);
            }
        }
    };

    const handleMouseLeave = () => {
        if (!switchButton && !disableMode) {
            // if (mode === Mode.BACK) {
            //     setMode(Mode.FRONT);
            // } else if (mode === Mode.FRONT && product.backImage) {
            //     setMode(Mode.BACK);
            // }
            setMode(Mode.FRONT);
        }
    };

    const options = [
        {
            label: Mode.FRONT,
            value: Mode.FRONT,
        },
        {
            label: Mode.BACK,
            value: Mode.BACK,
        },
    ];

    const backgroundColor =
        color ||
        product.color_code ||
        product.colors?.[product.colors.length - 1]?.code ||
        '#ffffff';

    return (
        <Box className="w-100">
            <Box className="aspectView">
                <Box
                    style={{
                        backgroundColor,
                        backgroundImage: `url(${mode === Mode.FRONT ? product.image : product.backImage
                            })`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                >
                    <Box className={`productDesign ${slug} ${mode}`}>
                        {product.slug.toLowerCase() === 'case' && (
                            <LazyLoadImage
                                style={{ zIndex: 2 }}
                                src={camImage}
                                className="cameraPng"
                            />
                        )}
                        {mode === Mode.FRONT && fDesign && (
                            <LazyLoadImage src={fDesign} alt={product.name} />
                        )}
                        {mode === Mode.BACK && bDesign && (
                            <LazyLoadImage src={bDesign} alt={product.name} />
                        )}
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default ProductImageWithDesign;
