import { CircularProgress, Typography } from '@material-ui/core';
import React from 'react';

const Loading = ({ loading, text = 'Loading' }) => {
  if (!loading) return null;

  return (
    <div className="loading-overlayer">
      <div className="loading-content">
        <CircularProgress size={20} />
        <Typography style={{ paddingLeft: 10 }}>{text}</Typography>
      </div>
    </div>
  );
};

export default Loading;
