import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import PhoneInput from 'react-phone-input-2';
import { Box, Typography } from '@material-ui/core';
import 'react-phone-input-2/lib/material.css';

const useStyles = makeStyles({
  phoneNo: {
    width: '100% !important',
    padding: '16px 10px 18px!important',
    paddingLeft: '50px!important',
  },
  error: {
    marginTop: '5px',
    color: '#FF4842',
    marginLeft: '14px',
    fontSize: '0.75rem',
  },
});

const PhoneNumberInput = ({ phoneNo, setPhoneNo, error, inputStyle = {} }) => {
  const classes = useStyles();
  const [phoneNumber, setPhoneNumber] = useState(phoneNo);

  const handleChange = phoneNumber => {
    setPhoneNumber(phoneNumber);
    setPhoneNo(phoneNumber);
  };

  return (
    <Box>
      <PhoneInput
        inputClass={classes.phoneNo}
        country={'us'}
        placeholder="201-554-5544"
        value={phoneNumber || ''}
        onChange={phone => handleChange(phone)}
        isValid={!error}
        inputStyle={inputStyle}
      />
      {error && <Typography className={classes.error}>{error}</Typography>}
    </Box>
  );
};

export default PhoneNumberInput;
