import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Box } from '@material-ui/core';
import AdminLayout from '../../layouts/AdminLayout';
import useSWR from 'swr';
import { fetcher } from '../../utils/api';
import SearchBar from '../../components/SearchBar';
import Header from './Header';

const useStyles = makeStyles(() => ({
  root: {
    padding: 24,
  },
  results: {
    marginTop: 12,
  },
}));

const ProductVariants = () => {
  const classes = useStyles();
  const [variants, setVariants] = React.useState([]);

  const { data } = useSWR('/store/variants', fetcher);

  useEffect(() => {
    if (data?.code === 200) {
      setVariants(data.result);
    }
  }, [data]);

  const handleSearch = () => {};

  return (
    <AdminLayout>
      <Box className={classes.root}>
        <Header />
        <SearchBar onSearch={handleSearch} />
      </Box>
    </AdminLayout>
  );
};

export default ProductVariants;
