export const rgbToHex = rgb => {
  const rgbRegex = /^rgb\(\s*(-?\d+)(%?)\s*,\s*(-?\d+)(%?)\s*,\s*(-?\d+)(%?)\s*\)$/;
  let result;
  let r;
  let g;
  let b;
  let hex = '';
  if ((result = rgbRegex.exec(rgb))) {
    r = window.componentFromStr(result[1], result[2]);
    g = window.componentFromStr(result[3], result[4]);
    b = window.componentFromStr(result[5], result[6]);
    hex = '#' + (0x1000000 + (r << 16) + (g << 8) + b).toString(16).slice(1);
  }
  return hex;
};

export const getColor = bgColor => {
  bgColor = bgColor.replace(/ /g, '');
  if (bgColor.includes('rgb')) {
    bgColor = rgbToHex(bgColor);
  }
  const color = bgColor.charAt(0) === '#' ? bgColor.substring(1, 7) : bgColor;
  const r = parseInt(color.substring(0, 2), 16); // hexToR
  const g = parseInt(color.substring(2, 4), 16); // hexToG
  const b = parseInt(color.substring(4, 6), 16); // hexToB
  return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? '#555555' : '#ffffff';
};
