import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Box, Container } from '@material-ui/core';
import Header from './Header';
import Overview from './Overview';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: 12,
    paddingBottom: 12,
  },
}));

const DashboardAnalytics = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Container maxWidth={false}>
        <Header />
        <Overview />
      </Container>
    </Box>
  );
};

export default DashboardAnalytics;
