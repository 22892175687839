import {
  Typography,
  TextField,
  Grid,
  Box,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Table,
  TableBody,
  TableRow,
  TableCell,
  makeStyles,
} from '@material-ui/core';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Navigate, useLocation, useParams } from 'react-router-dom';
import useSWR from 'swr';
import ProductInfo from '../../components/product/ProductInfo';
import ProductMappings from '../../components/product/ProductMappings';
import ProductVariant from '../../components/product/ProductVariant';
import AdminLayout from '../../layouts/AdminLayout';
import { fetcher } from '../../utils/api';

const ProductView = () => {
  const { id: productId } = useParams();
  const { data, mutate } = useSWR('v2/product/' + productId, fetcher);

  const [product, setProduct] = useState(null);

  useEffect(() => {
    if (data?.result) {
      setProduct(data.result);
    }
  }, [data]);

  return (
    <AdminLayout>
      <Box className="p-2">
        <Grid container spacing={2}>
          <Grid item md={3}>
            <ProductInfo product={product} onUpdate={mutate} />
          </Grid>
          <Grid item md={5}>
            <ProductVariant product={product} onUpdate={mutate} />
          </Grid>
          <Grid item md={4}>
            <ProductMappings product={product} onUpdate={mutate} />
          </Grid>
        </Grid>
      </Box>
    </AdminLayout>
  );
};

export default ProductView;
