import React from 'react';
import { useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import AddProductColorModal from './AddProductColorModal';
import EditProductColorModal from './EditProductColorModal';
import DeleteConfirmModal from '../modals/DeleteConfirmModal';
import { deleteProductMappings } from '../../utils/api';
import ToastManager from '../toaster/ToastManager';
import AddProductSizeModal from './AddProductSizeModal';
import EditProductSizeModal from './EditProductSizeModal';

const ProductVariant = ({ product, onUpdate }) => {
  const [openAddColorModal, setOpenAddColorModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openAddSizeModal, setOpenAddSizeModal] = useState(false);
  const [selectedColor, setSelectedColor] = useState(null);
  const [selectedSize, setSelectedSize] = useState(null);
  const [loading, setLoading] = useState(false);

  const openColorModal = () => {
    setOpenAddColorModal(true);
  };

  const closeColorModal = () => {
    setOpenAddColorModal(false);
    onUpdate();
  };

  const closeEditModal = () => {
    setSelectedColor(null);
    setSelectedSize(null);
    setOpenEditModal(false);
    onUpdate();
  };

  const handleEdit = item => {
    setSelectedColor(item);
    setOpenEditModal(true);
  };

  const handleDelete = item => {
    setSelectedColor(item);
    setOpenDeleteModal(true);
  };

  const handleSizeDelete = sizeItem => {
    setSelectedSize(sizeItem);
    setOpenDeleteModal(true);
  };

  const closeDeleteModal = () => {
    setSelectedColor(null);
    setOpenDeleteModal(false);
  };

  const deleteColorMappings = async () => {
    if (!(selectedColor || selectedSize)) {
      return;
    }

    try {
      setLoading(true);
      const variantIds = (selectedColor || selectedSize).variants.map(item => item.id);
      await deleteProductMappings({ variantIds });
      onUpdate();
      ToastManager.success('1 color is deleted.');
    } catch (error) {
      console.error(error);
      ToastManager.error('Something went to wrong, Try again.');
    }
    closeDeleteModal();
    setLoading(false);
  };

  const handleEditSize = sizeItem => {
    setSelectedSize(sizeItem);
    setOpenEditModal(true);
  };

  return (
    <>
      <Card>
        <CardHeader title="Product Variants" />
        <Divider />
        <CardContent>
          {product ? (
            <>
              <Typography>
                <b>Colors ({product.colors.length})</b>
              </Typography>
              <Table>
                <TableBody>
                  {product.colors.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <div
                          className="colorItem"
                          style={{
                            backgroundColor: item.code,
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        <Typography>{item.label}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>{item.code}</Typography>
                      </TableCell>
                      <TableCell>
                        <Box className="flexCenter">
                          <Button
                            variant="contained"
                            size="small"
                            startIcon={<EditOutlinedIcon />}
                            onClick={() => handleEdit(item)}
                          >
                            Edit
                          </Button>
                          <Button
                            className="ml-1"
                            variant="contained"
                            color="secondary"
                            size="small"
                            startIcon={<DeleteOutlinedIcon />}
                            onClick={() => handleDelete(item)}
                          >
                            Delete
                          </Button>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <Button
                className="mt-2"
                startIcon={<AddOutlinedIcon />}
                variant="contained"
                color="primary"
                onClick={openColorModal}
              >
                Add More Colors
              </Button>
              <Divider className="my-3" />
              <Typography>
                <b>Sizes ({product.sizes.length})</b>
              </Typography>
              <Box>
                {product.sizes.map((size, index) => (
                  <Box className="flexCenterBetween m-1" key={index}>
                    <div className="box">
                      <Typography>{size.size}</Typography>
                    </div>
                    <Box>
                      <Button
                        variant="contained"
                        size="small"
                        startIcon={<EditOutlinedIcon />}
                        onClick={() => handleEditSize(size)}
                      >
                        Edit
                      </Button>
                      <Button
                        className="ml-1"
                        variant="contained"
                        color="secondary"
                        size="small"
                        startIcon={<DeleteOutlinedIcon />}
                        onClick={() => handleSizeDelete(size)}
                      >
                        Delete
                      </Button>
                    </Box>
                  </Box>
                ))}
                <Button
                  startIcon={<AddOutlinedIcon />}
                  color="primary"
                  className="mt-2"
                  variant="contained"
                  onClick={() => setOpenAddSizeModal(true)}
                >
                  Add More Size
                </Button>
              </Box>
            </>
          ) : (
            <Box className="flexCenterCenter p-2">
              <CircularProgress />
            </Box>
          )}
        </CardContent>
      </Card>
      <AddProductColorModal
        open={openAddColorModal}
        onClose={closeColorModal}
        product={product}
        onSave={onUpdate}
      />
      <EditProductColorModal
        open={openEditModal && !!selectedColor}
        onClose={closeEditModal}
        color={selectedColor}
      />
      <DeleteConfirmModal
        open={openDeleteModal}
        onClose={closeDeleteModal}
        onDelete={deleteColorMappings}
        loading={loading}
      />
      <AddProductSizeModal
        open={openAddSizeModal}
        onClose={() => {
          setOpenAddSizeModal(false);
        }}
        product={product}
        onSave={onUpdate}
      />
      <EditProductSizeModal
        open={openEditModal && !!selectedSize}
        onClose={closeEditModal}
        size={selectedSize}
      />
    </>
  );
};

export default ProductVariant;
