import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography, Button } from '@material-ui/core';
import AddNewProductModal from '../../components/product/AddNewProductModal';
import { useState } from 'react';

const useStyles = makeStyles(() => ({
  root: {},
}));

function Header({ className, ...rest }) {
  const classes = useStyles();

  const [openAddModal, setOpenAddModal] = useState(false)

  const handleOpenAddModal = () => {
    setOpenAddModal(true)
  }

  const handleCloseAddModal = () => {
    setOpenAddModal(false)
  }

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <AddNewProductModal open={openAddModal} onClose={handleCloseAddModal} />
      <Grid alignItems="flex-end" container justifyContent="space-between" spacing={3}>
        <Grid item>
          <Typography component="h1" variant="h3">
            Products
          </Typography>
        </Grid>
        <Grid item>
          <Button color="primary" variant="contained" onClick={handleOpenAddModal}>
            Add Product
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}

Header.propTypes = {
  className: PropTypes.string,
};

export default Header;
