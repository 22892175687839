import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  Grid,
  Modal,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import clsx from 'clsx';
import { useState } from 'react';
import { useEffect } from 'react';
import ToastManager from '../toaster/ToastManager';
import { updateProductMappings } from '../../utils/api';

const EditProductColorModal = ({ open, onClose, color, className, ...rest }) => {
  const classes = useStyles();
  const [colorName, setColorName] = useState('');
  const [loading, setLoading] = useState(false);
  const [variants, setVariants] = useState([]);

  useEffect(() => {
    if (color) {
      console.log(color.variants);
      setColorName(color.label);
      setVariants(color.variants || []);
    }
  }, [color]);

  const handleVariantIdChange = (e, index) => {
    variants[index].variantId = e.target.value;
    setVariants([...variants]);
  };

  const handleActiveChange = (e, index) => {
    variants[index].active = e.target.checked;
    setVariants([...variants]);
  };

  const onUpdate = async () => {
    try {
      for (const variantItem of variants) {
        if (variantItem.active && !variantItem.variantId) {
          return ToastManager.error('Printful variant ID is required.');
        }
      }
      setLoading(true);
      const updatedMappings = variants.map(item => ({
        ...item,
        color_label: colorName,
      }));
      await updateProductMappings({ mappings: updatedMappings });
      ToastManager.success('Successfully updated.');
      setLoading(false);
      onClose();
    } catch (error) {
      console.error(error);
      setLoading(false);
      return ToastManager.error('Something went to wrong. Try again please.');
    }
  };

  return (
    <Modal onClose={onClose} open={open}>
      <Card {...rest} className={clsx(classes.root, className)}>
        <form>
          <CardHeader title="Add New Color" />
          <Divider />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item md={2}>
                <div
                  className="colorItem"
                  style={{
                    width: 50,
                    height: 50,
                    backgroundColor: color?.code,
                  }}
                />
              </Grid>
              <Grid item md={5}>
                <TextField
                  fullWidth
                  label="Color Name"
                  onChange={e => setColorName(e.target.value.toLowerCase())}
                  value={colorName}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={5}>
                <TextField
                  fullWidth
                  label="Hex Code"
                  disabled
                  defaultValue={color?.code}
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <Box className="my-2">
              <Typography>
                <b>Size Mappings</b>
              </Typography>
              <Table className="mt-2">
                <TableBody>
                  <TableRow>
                    <TableCell>Size</TableCell>
                    <TableCell>Active</TableCell>
                    <TableCell>Printful Variant ID</TableCell>
                  </TableRow>
                  {variants.map((variantItem, index) => (
                    <TableRow selected={index % 2 === 0} key={index}>
                      <TableCell className="p-1">
                        <Typography>{variantItem.size}</Typography>
                      </TableCell>
                      <TableCell className="p-1">
                        <Switch
                          color="primary"
                          checked={variantItem.active}
                          onChange={e => handleActiveChange(e, index)}
                        />
                      </TableCell>
                      <TableCell className="p-1">
                        <TextField
                          fullWidth
                          onChange={e => handleVariantIdChange(e, index)}
                          value={variantItem.variantId}
                          variant="outlined"
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </CardContent>
          <Divider />
          <CardActions className={classes.actions}>
            <Button onClick={onClose}>Close</Button>
            <Button
              color="primary"
              onClick={onUpdate}
              variant="contained"
              disabled={loading}
              startIcon={loading ? <CircularProgress size={16} /> : null}
            >
              Update
            </Button>
          </CardActions>
        </form>
      </Card>
    </Modal>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: 500,
    maxHeight: '100%',
    overflowY: 'auto',
    maxWidth: '100%',
  },
  actions: {
    justifyContent: 'flex-end',
  },
}));

export default EditProductColorModal;
