import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Box } from '@material-ui/core';
import AdminLayout from '../../layouts/AdminLayout';
import useSWR from 'swr';
import { fetcher } from '../../utils/api';
import SearchBar from '../../components/SearchBar';
import Header from './Header';
import OrdersTable from './OrdersTable';
import Loading from '../../components/Loading';

const useStyles = makeStyles(() => ({
  root: {
    padding: 24,
  },
  results: {
    marginTop: 12,
  },
}));

const SampleOrders = () => {
  const classes = useStyles();
  const [orders, setOrders] = React.useState([]);
  const [filteredOrders, setFilteredOrders] = React.useState([]);

  const { data } = useSWR('v2/sample-orders', fetcher);

  useEffect(() => {
    if (data?.code === 200) {
      setOrders(data.result);
      setFilteredOrders(data.result);
    }
  }, [data]);

  const handleSearch = text => {};

  console.log(filteredOrders);

  return (
    <AdminLayout>
      <Loading loading={!data?.result} />
      <Box className={classes.root}>
        <Header title="Sample Orders" />
        <SearchBar onSearch={handleSearch} />
        <OrdersTable orders={filteredOrders} />
      </Box>
    </AdminLayout>
  );
};

export default SampleOrders;
