import AdminLayout from '../../layouts/AdminLayout';
import React, { useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { calculateProfit } from '../../utils/calculateProfit';
import classes from '../../globalcss/dash.module.css';
import { Box, Button, Typography } from '@material-ui/core';
import Dialog from '../../components/dialog/Dialog';
import useSWR from 'swr';
import { cancelOrder, fetcher } from '../../utils/api';
import Loading from '../../components/Loading';

const OrderView = () => {
  const navigate = useNavigate();
  const { orderId } = useParams();

  const { data, mutate } = useSWR('/v2/order/' + orderId, fetcher);

  const [showModal, setShowModal] = React.useState(false);
  const [noteText, setNoteText] = React.useState('');
  const [loading, setLoading] = useState(false);

  async function createNote(no, text) {
    fetch(`http://localhost:666/${(no, text)}`);
  }

  async function handleAddNote(orderNo, text) {
    const data = { noteText: text };
    fetch(`http://localhost:666/order/createNote/${orderNo}/${text}`, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      body: JSON.stringify(data),
    });
  }

  async function handleDeleteNote(orderNo) {
    fetch(`http://localhost:666/order/deleteNote/${orderNo}`, {
      method: 'POST',
    });
  }
  const handleCancelOrder = () => {
    Dialog.delete({
      description: 'Do you want to really delete this item?',
    }).then(async res => {
      if (res) {
        setLoading(true);
        await cancelOrder(order.orderNo);
        await mutate();
        setLoading(false);
      }
    });
  };

  const order = data?.result;

  if (!order || loading) {
    return <Loading loading={true} />;
  }

  const customer = order.printfulOrderMetadata.recipient;

  console.log(order)

  return (
    <AdminLayout>
      <Box className="flexCenter flexCol" sx={{ p: 5 }}>
        <h2>#{order.orderNo}</h2>
        {order.status === 'cancelled_by_printful' ? (
          <h3 style={{ color: 'red' }}>Order Cancelled</h3>
        ) : (
          <h3>{order.status}</h3>
        )}
        <Button
          variant="contained"
          onClick={() => setShowModal(true)}
          style={{ marginBottom: '1rem' }}
        >
          add note
        </Button>
        {order.orderNotes && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '50%',
              margin: 'auto',
              marginBottom: '2rem',
            }}
          >
            <p
              style={{
                backgroundColor: 'yellow',
                fontSize: '2rem',
                minWidth: '200px',
              }}
            >
              {order.orderNotes}
            </p>
            <button onClick={() => handleDeleteNote(order.orderNo)}>x</button>
          </div>
        )}
        {order.status !== 'cancelled_by_printful' && (
          <Button variant="contained" onClick={handleCancelOrder}>
            Cancel Order
          </Button>
        )}
        {showModal && (
          <div className={classes.orderModal} onClick={() => setShowModal(false)}>
            <div className={classes.modalContent} onClick={e => e.stopPropagation()}>
              <h3>add note</h3>
              <textarea
                onChange={e => setNoteText(e.target.value)}
                style={{
                  width: '300px',
                  height: '200px',
                  padding: '.4rem',
                  marginBottom: '1rem',
                }}
              />
              <button onClick={() => handleAddNote(order.orderNo, noteText)}>add</button>
            </div>
          </div>
        )}
        <div
          style={{
            marginBottom: '2rem',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <p style={{ marginBottom: '.4rem', textDecoration: 'none' }}>
            order id:
            {order?.paymentId?.orderId
              ? order.paymentId.orderId
              : 'this will be generated once the money is received'}
          </p>
          <Link
            to={`/store/${order.vendorId}`}
            style={{ marginBottom: '.4rem', textDecoration: 'none' }}
          >
            seller: {order.vendorId}
          </Link>
          <a href={`mailto:${customer.email}`} style={{ textDecoration: 'none' }}>
            recipient: {customer.email}
          </a>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '2rem',
          }}
        >
          <div className={classes.customerCards}>
            <div style={{ marginRight: '20px' }}>
              <h4>Customer Details</h4>
              <p style={{ textTransform: 'capitalize' }}>
                {order.billingAddress?.firstName} {order.billingAddress?.lastName}
              </p>
              <p style={{ textTransform: 'capitalize' }}>{order.billingAddress?.phoneNo}</p>
              <p>{order.billingAddress?.email}</p>
            </div>
            <div>
              <h4>Address</h4>
              <p style={{ textTransform: 'capitalize' }}>{order.billingAddress.street}</p>
              <p style={{ textTransform: 'capitalize' }}>{order.billingAddress.aptNo}</p>
              <p style={{ textTransform: 'capitalize' }}>{customer.zip}</p>
              <p>{`${customer.city}, ${customer.state_name ? customer.state_name : customer.country_name
                }`}</p>
            </div>
          </div>
        </div>
        <div className={classes.cards}>
          <div className={classes.orderDetailCard} style={{ marginRight: '20px' }}>
            <h4>Printful Cost</h4>
            <Typography>Subtotal: ${order.printfulOrderMetadata.costs.subtotal}</Typography>
            <Typography>Shipping: ${order.printfulOrderMetadata.costs.shipping}</Typography>
            <Typography>Tax: ${order.price.tax} - {order.tax * 100}%</Typography>
            <Typography>Total: ${order.printfulOrderMetadata.costs.total}</Typography>
            <Typography>Remaining Tax: ${Number(order.price.taxAndFee) - Number(order.price.tax)}</Typography>
          </div>
          <div className={classes.orderDetailCard} style={{ marginRight: '20px' }}>
            <h4>Amount Paid</h4>
            <Typography>Total: ${order.totalAmount.toFixed(2)}</Typography>
            <Typography>Price: ${order.price.subTotal}</Typography>
            <Typography>Tax: ${(Number(order.price.taxAndFee) - 1.47).toFixed(2)}</Typography>
            <Typography>Fee: $1.47 </Typography>
            <Typography>Tax and Fee: ${order.price.taxAndFee} </Typography>
            <Typography>Shipping: ${order.shippingCost.toFixed(2)}</Typography>
            {/* tax payment from 1 purchase */}
          </div>
          <div className={classes.orderDetailCard} style={{ marginRight: '20px' }}>
            <h4>Payments</h4>
            <Typography>Total profit: ${order.profit.total}</Typography>
            <Typography>
              Merchpals profit:{' '}
              <span style={{ backgroundColor: 'yellow' }}>
                ${order.profit.mp}
              </span>
            </Typography>
            <Typography>Vendor profit: ${order.profit.vendor}</Typography>
            <Typography>Fee: $ 1.47</Typography>
            <Typography>New Fee: $ 2.53</Typography>
          </div>
          {order.isReferral && (
            <div className={classes.orderDetailCard}>
              <h4>Referral amount</h4>
              <p>Referral amount: ${(calculateProfit(order) * 0.1).toFixed(2)}</p>
            </div>
          )}
        </div>
      </Box>
    </AdminLayout>
  );
};

export default OrderView;
