import React from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Typography,
} from '@material-ui/core';
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded';

const DeleteConfirmModal = ({ onClose, open, onDelete, loading }) => {
  return (
    <Dialog onClose={onClose} open={open}>
      <DialogContent>
        <Box className="p-4">
          <Box className="flexCenterCenter mb-3">
            <HighlightOffRoundedIcon style={{ fontSize: 60 }} color="secondary" />
          </Box>
          <Typography>Are you sure you want to really delete this item?</Typography>
          <Box className="flexCenterCenter mt-4">
            <Button variant="contained" color="default" onClick={onClose}>
              Close
            </Button>
            <Button
              variant="contained"
              color="secondary"
              className="ml-2"
              onClick={onDelete}
              disabled={loading}
              startIcon={loading ? <CircularProgress size={16} /> : null}
            >
              Delete
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default DeleteConfirmModal;
