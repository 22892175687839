import React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Typography,
  Box,
} from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import ViewIcon from '@material-ui/icons/VisibilityOutlined';
import { When } from 'react-if';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    paddingBottom: 0,
  },
  content: {
    padding: 0,
    '&:last-child': {
      paddingBottom: 0,
    },
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  description: {
    padding: theme.spacing(2, 3, 1, 3),
  },
  details: {
    padding: theme.spacing(2, 3, 1, 3),
  },
  colorItem: {
    width: 24,
    height: 24,
    borderRadius: 2,
    border: 'solid 1px #00000034',
    marginRight: 4,
    marginTop: 4,
  },
  sizeItem: {
    height: 40,
    minWidth: 40,
    borderRadius: 2,
    border: 'solid 1px #00000034',
    padding: '0 4px',
    marginTop: 4,
    marginRight: 4,
    textTransform: 'uppercase',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const ProductCard = ({ product, className, ...rest }) => {
  const navigate = useNavigate();
  const classes = useStyles();

  const onEdit = () => {
    navigate('/products/' + product.id);
  };

  return (
    <>
      <Card {...rest} className={clsx(classes.root, className)}>
        <CardHeader
          className={classes.header}
          disableTypography
          title={<Typography variant="h5">{product.name}</Typography>}
        />
        <CardContent className={classes.content}>
          <div className={classes.description}>
            <Box className="flex">
              <Box className="flex">
                <Box className="w-80px">
                  <img src={product.image} className="w-100" />
                </Box>
                <Box className="ml-8px">
                  <Typography>Front Image</Typography>
                  <Typography>View</Typography>
                  <Button size="small" startIcon={<CloudUploadIcon />}>
                    Upload
                  </Button>
                </Box>
              </Box>
              {product.backImage && (
                <Box className="flex">
                  <Box className="w-80px ml-8px">
                    <img src={product.backImage} className="w-100" />
                  </Box>
                  <Box className="ml-8px">
                    <Typography>Back Image</Typography>
                    <Typography>View</Typography>
                    <Button size="small" color="default" startIcon={<CloudUploadIcon />}>
                      Upload
                    </Button>
                  </Box>
                </Box>
              )}
            </Box>
            <When condition={product.colors.length > 0}>
              <Typography>Colors</Typography>
              <Box className="flexCenter flexWrap">
                {product.colors.map((color, index) => (
                  <Box
                    key={index}
                    className={classes.colorItem}
                    style={{ backgroundColor: color.code }}
                  />
                ))}
              </Box>
            </When>
            <When condition={product.sizes.length > 0}>
              <Typography>Sizes</Typography>
              <Box className="flexCenter flexWrap">
                {product.sizes.map((size, index) => (
                  <Box key={index} className={classes.sizeItem}>
                    <Typography>{size.size}</Typography>
                  </Box>
                ))}
              </Box>
            </When>
          </div>
          <Divider style={{ marginTop: 'auto' }} />
          <div className={classes.details}>
            <Grid alignItems="center" container justifyContent="space-between" spacing={3}>
              <Grid item>
                <Typography variant="h5">$ {product.minPrice}</Typography>
                <Typography variant="body2">Min Price</Typography>
              </Grid>
              <Grid item>
                <Typography variant="h5">${product.basePrice}</Typography>
                <Typography variant="body2">Base Price</Typography>
              </Grid>
              <Grid item>
                <Button
                  size="small"
                  variant="contained"
                  startIcon={<ViewIcon />}
                  onClick={onEdit}
                  color="primary"
                >
                  View
                </Button>
              </Grid>
            </Grid>
          </div>
        </CardContent>
      </Card>
    </>
  );
};

ProductCard.propTypes = {
  className: PropTypes.string,
  product: PropTypes.object.isRequired,
};

export default ProductCard;
