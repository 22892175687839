import { Box, Container, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import ControlPanel from '../../components/settings/ControlPanel';
import AdminLayout from '../../layouts/AdminLayout';

const useStyles = makeStyles(() => ({
  root: {
    padding: 24,
  },
  results: {
    marginTop: 12,
  },
}));

const Setting = () => {
  const classes = useStyles();

  return (
    <AdminLayout>
      <Box className={classes.root}>
        <Container>
          <Grid container>
            <Grid item xs={12} md={4}>
              <ControlPanel />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </AdminLayout>
  );
};

export default Setting;
