import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Button, TextField } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { useAuth } from './auth/AuthProvider';
import { Navigate } from 'react-router-dom';
import PhoneNumberInput from './PhoneNumberInput';

const useStyles = makeStyles(theme => ({
  fields: {
    marginTop: 20,
  },
  submitButton: {
    marginTop: 20,
    width: '100%',
  },
}));

const LoginForm = () => {
  const { login, isLoggedIn, error } = useAuth();
  const classes = useStyles();

  const [phoneNo, setPhoneNo] = React.useState('');
  const [password, setPassword] = React.useState('');

  const handleLogin = () => {
    login(phoneNo, password);
  };

  if (isLoggedIn) {
    return <Navigate to="/dashboard" />;
  }

  return (
    <div className={classes.fields}>
      <PhoneNumberInput phoneNo={phoneNo} setPhoneNo={val => setPhoneNo(val)} />

      <TextField
        fullWidth
        label="Password"
        name="password"
        type="password"
        variant="outlined"
        style={{ marginTop: 10 }}
        onChange={e => setPassword(e.target.value)}
      />

      <Button
        className={classes.submitButton}
        color="primary"
        size="large"
        type="submit"
        variant="contained"
        onClick={handleLogin}
      >
        Sign in
      </Button>
      {error && (
        <Alert style={{ marginTop: 10 }} severity="error">
          {error}
        </Alert>
      )}
    </div>
  );
};

export default LoginForm;
