import React from 'react';
import ReactDOM from 'react-dom/client';
import DialogComponent from './DialogComponent';
import CloseIcon from '@material-ui/icons/Close';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

export default class Dialog {
  dialogRoot;
  options = {
    type: 'default',
  };
  static dialog;

  constructor() {
    let dialogContainer = document.getElementById('dialog-container-main');
    if (dialogContainer === null) {
      dialogContainer = document.createElement('div');
      dialogContainer.id = 'dialog-container-main';
      document.body.insertAdjacentElement('beforeend', dialogContainer);
      this.dialogRoot = ReactDOM.createRoot(dialogContainer);
    }
  }

  static getInstance() {
    if (!this.dialog) {
      this.dialog = new Dialog();
    }
    return this.dialog;
  }

  static delete(options) {
    return new Promise(resolve => {
      const instance = Dialog.getInstance();
      options.type = 'delete';
      options.icon = <CloseIcon color="error" style={{ fontSize: 48 }} />;
      options.onConfirm = () => {
        instance.clear();
        resolve(true);
      };
      options.onCancel = () => {
        instance.clear();
        resolve(false);
      };
      instance.show(options);
    });
  }

  static confirm(options) {
    return new Promise(resolve => {
      const instance = Dialog.getInstance();
      options.type = 'confirm';
      options.icon = <HelpOutlineIcon color="warning" style={{ fontSize: 48 }} />;
      options.onConfirm = () => {
        instance.clear();
        resolve(true);
      };
      options.onCancel = () => {
        instance.clear();
        resolve(false);
      };
      instance.show(options);
    });
  }

  static clear() {
    Dialog.getInstance().clear();
  }

  show(options) {
    this.options = {
      ...this.options,
      ...options,
    };
    this.render();
  }

  clear() {
    this.dialogRoot.render(null);
  }

  render() {
    this.dialogRoot.render(<DialogComponent {...this.options} />);
  }
}
