import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Avatar,
  Link,
} from '@material-ui/core';
import GenericMoreButton from '../../components/GenericMoreButton';
import TableEditBar from '../../components/TableEditBar';
import { useNavigate } from 'react-router-dom';
import getInitials from '../../utils/getInitials';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 10,
  },
  content: {
    padding: 0,
  },
  inner: {
    minWidth: 700,
  },
  nameCell: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    height: 42,
    width: 42,
    marginRight: theme.spacing(1),
  },
  actions: {
    padding: theme.spacing(1),
    justifyContent: 'flex-end',
  },
}));

const OrdersTable = ({ className, orders, ...rest }) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleSelectAll = event => {
    const selectedOrders = event.target.checked ? orders.map(customer => customer.id) : [];

    setSelectedOrders(selectedOrders);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedOrders.indexOf(id);
    let newSelectedOrders = [];

    if (selectedIndex === -1) {
      newSelectedOrders = newSelectedOrders.concat(selectedOrders, id);
    } else if (selectedIndex === 0) {
      newSelectedOrders = newSelectedOrders.concat(selectedOrders.slice(1));
    } else if (selectedIndex === selectedOrders.length - 1) {
      newSelectedOrders = newSelectedOrders.concat(selectedOrders.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedOrders = newSelectedOrders.concat(
        selectedOrders.slice(0, selectedIndex),
        selectedOrders.slice(selectedIndex + 1),
      );
    }

    setSelectedOrders(newSelectedOrders);
  };

  const handleChangePage = (event, page) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(event.target.value);
  };

  const handleClickOrderItem = order => {
    navigate('/order/' + order._id);
  };

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Typography color="textSecondary" gutterBottom variant="body2">
        {orders.length} Records found. Page {page + 1} of {Math.ceil(orders.length / rowsPerPage)}
      </Typography>
      <Card>
        <CardHeader action={<GenericMoreButton />} title="Sample orders" />
        <Divider />
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Order Number</TableCell>
                    <TableCell>Store</TableCell>
                    <TableCell>Item Count</TableCell>
                    <TableCell>Total Amount</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Stripe Fee</TableCell>
                    <TableCell>Tax</TableCell>
                    <TableCell>Shared Revenue</TableCell>
                    <TableCell>Printful</TableCell>
                    <TableCell>Profit</TableCell>
                    <TableCell>Created At</TableCell>
                    <TableCell align="right">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {orders
                    .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
                    .map((order, index) => (
                      <TableRow
                        hover
                        key={index}
                        selected={selectedOrders.indexOf(order.id) !== -1}
                      >
                        <TableCell> {order.orderNo} </TableCell>
                        <TableCell>
                          {
                            order.store && (
                              <div className={classes.nameCell}>
                                <Avatar className={classes.avatar} src={order.store.logo}>
                                  {getInitials(order.store.name)}
                                </Avatar>
                                <div>
                                  <Link
                                    color="inherit"
                                    component={RouterLink}
                                    to={`/stores/${order.store.id}`}
                                    variant="h6"
                                  >
                                    {order.store.name}
                                  </Link>
                                </div>
                              </div>
                            )
                          }
                        </TableCell>
                        <TableCell> {order.products.length} </TableCell>
                        <TableCell> ${order.totalAmount} </TableCell>
                        <TableCell> {order.status} </TableCell>
                        <TableCell>${order.price?.stripeFee}</TableCell>
                        <TableCell>
                          <div style={{ textDecoration: 'line-through', textDecorationColor: 'red' }}>${order.price?.taxAndFee}</div>
                          ${order.price?.tax}
                        </TableCell>
                        <TableCell>${order.price?.sharedRevenue}</TableCell>
                        <TableCell>${order.price?.printful}</TableCell>
                        <TableCell>
                          <b>${order.profit?.total}</b>
                          {
                            !order.isPrivate && (
                              <>
                                <div>Merchpals: ${order.profit?.mp}</div>
                                <div>Vendor: ${order.profit?.vendor}</div>
                                {order.profit?.referrer && <div>Referrer: ${order.profit?.referrer}</div>}
                              </>
                            )
                          }
                        </TableCell>
                        <TableCell> {order.createdAt} </TableCell>
                        <TableCell align="right">
                          <Button
                            color="primary"
                            size="small"
                            variant="outlined"
                            onClick={() => {
                              handleClickOrderItem(order);
                            }}
                          >
                            View
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>
        <CardActions className={classes.actions}>
          <TablePagination
            component="div"
            count={orders.length}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </CardActions>
      </Card>
      <TableEditBar selected={selectedOrders} />
    </div>
  );
};

OrdersTable.propTypes = {
  className: PropTypes.string,
  orders: PropTypes.array,
};

OrdersTable.defaultProps = {
  orders: [],
};

export default OrdersTable;
